@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-MediumItalic.66155d61.eot);
    src: local('Graphik LCG Medium Italic'), local('GraphikLCG-MediumItalic'),
        url(../../assets/fonts/GraphikLCG-MediumItalic.66155d61.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-MediumItalic.4f566b4b.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-MediumItalic.d736acb1.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-MediumItalic.e2d23843.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-SuperItalic.f7896001.eot);
    src: local('Graphik LCG Super Italic'), local('GraphikLCG-SuperItalic'),
        url(../../assets/fonts/GraphikLCG-SuperItalic.f7896001.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-SuperItalic.4d0a55d3.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-SuperItalic.e1ca0193.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-SuperItalic.02c470a7.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-SemiboldItalic.2d6f36c5.eot);
    src: local('Graphik LCG Semibold Italic'), local('GraphikLCG-SemiboldItalic'),
        url(../../assets/fonts/GraphikLCG-SemiboldItalic.2d6f36c5.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-SemiboldItalic.03f9b3f3.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-SemiboldItalic.e66fdd03.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-SemiboldItalic.2bb4fb82.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-Black.0e0d4042.eot);
    src: local('Graphik LCG Black'), local('GraphikLCG-Black'),
        url(../../assets/fonts/GraphikLCG-Black.0e0d4042.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-Black.518fff7c.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-Black.efe98be2.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-Black.ba664de4.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-Super.e47a05cf.eot);
    src: local('Graphik LCG Super'), local('GraphikLCG-Super'),
        url(../../assets/fonts/GraphikLCG-Super.e47a05cf.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-Super.c5306116.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-Super.cf860eaa.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-Super.813fa953.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-Thin.132b198a.eot);
    src: local('Graphik LCG Thin'), local('GraphikLCG-Thin'),
        url(../../assets/fonts/GraphikLCG-Thin.132b198a.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-Thin.feaba822.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-Thin.d21200d9.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-Thin.806b6831.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-ExtralightItalic.764e5bc5.eot);
    src: local('Graphik LCG Extralight Italic'), local('GraphikLCG-ExtralightItalic'),
        url(../../assets/fonts/GraphikLCG-ExtralightItalic.764e5bc5.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-ExtralightItalic.776f6040.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-ExtralightItalic.cda5990a.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-ExtralightItalic.0e3496ae.ttf) format('truetype');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-Regular.0f8c2658.eot);
    src: local('Graphik LCG Regular'), local('GraphikLCG-Regular'),
        url(../../assets/fonts/GraphikLCG-Regular.0f8c2658.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-Regular.2f338b5e.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-Regular.b1ae0f61.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-Regular.469bebb6.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-BoldItalic.20337ebd.eot);
    src: local('Graphik LCG Bold Italic'), local('GraphikLCG-BoldItalic'),
        url(../../assets/fonts/GraphikLCG-BoldItalic.20337ebd.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-BoldItalic.a2bb6726.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-BoldItalic.51a4afa6.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-BoldItalic.cac603fd.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-LightItalic.8517e01b.eot);
    src: local('Graphik LCG Light Italic'), local('GraphikLCG-LightItalic'),
        url(../../assets/fonts/GraphikLCG-LightItalic.8517e01b.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-LightItalic.1583180f.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-LightItalic.83b30957.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-LightItalic.dddbc193.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-BlackItalic.0c5a39db.eot);
    src: local('Graphik LCG Black Italic'), local('GraphikLCG-BlackItalic'),
        url(../../assets/fonts/GraphikLCG-BlackItalic.0c5a39db.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-BlackItalic.a0e712c0.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-BlackItalic.cf0f27e4.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-BlackItalic.99e957b2.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-Medium.f4fe0ff7.eot);
    src: local('Graphik LCG Medium'), local('GraphikLCG-Medium'),
        url(../../assets/fonts/GraphikLCG-Medium.f4fe0ff7.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-Medium.10b7bd9b.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-Medium.0d776a83.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-Medium.5d0a360b.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-Light.3f7f7c7b.eot);
    src: local('Graphik LCG Light'), local('GraphikLCG-Light'),
        url(../../assets/fonts/GraphikLCG-Light.3f7f7c7b.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-Light.91bde36b.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-Light.afc50e8a.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-Light.d73bdf48.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-Semibold.8f6e43d8.eot);
    src: local('Graphik LCG Semibold'), local('GraphikLCG-Semibold'),
        url(../../assets/fonts/GraphikLCG-Semibold.8f6e43d8.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-Semibold.04581b0e.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-Semibold.339c2e00.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-Semibold.f87eef8d.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-Bold.2239e7c3.eot);
    src: local('Graphik LCG Bold'), local('GraphikLCG-Bold'),
        url(../../assets/fonts/GraphikLCG-Bold.2239e7c3.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-Bold.0a863dc6.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-Bold.ccf9f0ec.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-Bold.b4721857.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-Extralight.bf638562.eot);
    src: local('Graphik LCG Extralight'), local('GraphikLCG-Extralight'),
        url(../../assets/fonts/GraphikLCG-Extralight.bf638562.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-Extralight.454a269e.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-Extralight.9b4d4436.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-Extralight.c1af7132.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-ThinItalic.81fefd79.eot);
    src: local('Graphik LCG Thin Italic'), local('GraphikLCG-ThinItalic'),
        url(../../assets/fonts/GraphikLCG-ThinItalic.81fefd79.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-ThinItalic.72858424.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-ThinItalic.85df45cb.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-ThinItalic.60e201c5.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url(../../assets/fonts/GraphikLCG-RegularItalic.13079459.eot);
    src: local('Graphik LCG Regular Italic'), local('GraphikLCG-RegularItalic'),
        url(../../assets/fonts/GraphikLCG-RegularItalic.13079459.eot?#iefix) format('embedded-opentype'),
        url(../../assets/fonts/GraphikLCG-RegularItalic.a5ac6edd.woff2) format('woff2'),
        url(../../assets/fonts/GraphikLCG-RegularItalic.5d583e71.woff) format('woff'),
        url(../../assets/fonts/GraphikLCG-RegularItalic.c94f6a58.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

